<script>
  let store = window.localStorage;
  let token = null;
  let token_ = store.getItem("token-st-omnitracs");
  if (token_ == null) {
    console.log("No login");
  } else {
    token = token_;
  }
  let saved_ok = false;
  let confirm_delete = false;
  let buscando = false;
  let edit_mode = false;
  let add_mode = false;
  let user_list = [];
  let data_user = {};
  let user_selected = -1;
  let user_name;
  let empleado_e, nombre_e, entidad_e, tel_e, pass_e, app_user, app_pass;
  let saved_text = "",
    saved_type = "";
  function map_user(usuario, c) {
    add_mode = false;
    user_selected = c;
    user_name = usuario;
    edit_mode = false;
  }
  async function get_data() {
    buscando = true;
    let r = await fetch(
      "https://st1-app.omnitracs.online/api/users?token=" + token + "&comm=list"
    );
    if (r.status == 200) {
      r = await r.json();
      console.log(r);
      let users = r.users;
      let u_data = r.u_data;
      users.sort(order_e);
      for (let x in users) {
        if (typeof u_data[users[x]] == "undefined") {
          u_data[users[x]] = {
            empleado: "-",
            nombre: users[x],
            entidad: "-",
            tel: "-",
          };
        }
      }
      data_user = u_data;
      user_list = users;
      store.setItem("token-st-omnitracs", token);
      let now = Math.round(new Date().getTime() / 1000);
      let expire = store.getItem("token-st-omnitracs-expire");
      if (!expire) {
        store.setItem("token-st-omnitracs-expire", now + 60 * 60);
      } else {
        if (now - expire > 0) {
          store.removeItem("token-st-omnitracs");
          store.removeItem("token-st-omnitracs-expire");
          token = null;
          app_user = "";
          app_pass = "";
        }
      }
    } else if (r.status == 400) {
      store.removeItem("token-st-omnitracs");
      store.removeItem("token-st-omnitracs-expire");
      token = null;
      app_user = "";
      app_pass = "";
      saved_ok = true;
      saved_text = "Usuario o password incorrecto";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    }
    buscando = false;
  }
  if (token) {
    get_data();
  }
  function order_e(a, b) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  }
  async function update_data() {
    buscando = true;
    let r = await fetch(
      "https://st1-app.omnitracs.online/api/users?token=" +
        token +
        "&comm=change&name=" +
        user_name +
        "&pass=" +
        pass_e +
        "&empleado=" +
        empleado_e +
        "&nombre=" +
        nombre_e +
        "&entidad=" +
        entidad_e +
        "&tel=" +
        tel_e
    );
    if (r.status == 200) {
      saved_ok = true;
      saved_text = "Usuario actualizado";
      saved_type = "alert-success";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    } else {
      saved_ok = true;
      saved_text = "Error en actualizar";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    }
    await get_data();
  }
  async function new_user() {
    buscando = true;
    let r = await fetch(
      "https://st1-app.omnitracs.online/api/users?token=" +
        token +
        "&comm=new" +
        "&name=" +
        user_name +
        "&pass=" +
        pass_e +
        "&empleado=" +
        empleado_e +
        "&nombre=" +
        nombre_e +
        "&entidad=" +
        entidad_e +
        "&tel=" +
        tel_e +
        "&pass=" +
        pass_e
    );
    if (r.status == 200) {
      saved_ok = true;
      saved_text = "Usuario guardado";
      saved_type = "alert-success";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    } else if (r.status == 201) {
      saved_ok = true;
      saved_text = "Usuario ya existe";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    } else {
      saved_ok = true;
      saved_text = "Usuario no se puede guardar";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    }
    await get_data();
  }
  async function del_user() {
    buscando = true;
    let r = await fetch(
      "https://st1-app.omnitracs.online/api/users?token=" +
        token +
        "&comm=del" +
        "&name=" +
        user_name
    );
    if (r.status == 200) {
      saved_ok = true;
      saved_text = "Usuario borrado";
      saved_type = "alert-success";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    } else {
      saved_ok = true;
      saved_text = "Usuario no se puede borrar";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 3000);
    }
    await get_data();
  }
</script>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}
{#if confirm_delete}
  <div
    class="modal fade {confirm_delete ? 'show' : ''}"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modaltitle"
    aria-hidden="true"
    style="padding-right: 17px; display: {confirm_delete
      ? 'block'
      : 'none'}; z-index:1039;"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      role="document"
      style="min-width: 20%"
    >
      <div class="modal-content" style="min-height: 30%">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="modaltitle">
            <i class="material-icons red md-36">warning</i> Confirmar borrado de
            usuario {user_name}<strong />
          </h5>
        </div>
        <div class="modal-body h-100 p-3 w-100">
          <div class="d-flex flex-column">Esta accion borrará el usuario.</div>
        </div>
        <div class="modal-footer" style="display: block;">
          <div class="d-flex justify-content-between">
            <div>
              <button
                on:click={() => {
                  confirm_delete = false;
                  user_selected = -1;
                  del_user();
                }}
                class="btn btn-success big"
                type="button"
              >
                <i class="material-icons md-85">done_outline</i>
                Confirmar
              </button>
            </div>
            <div>
              <button
                on:click={() => {
                  confirm_delete = false;
                }}
                class="btn btn-danger big"
                type="button"
              >
                <i class="material-icons md-85">not_interested</i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" style="z-index:1038" />
{/if}

{#if token != null}
  <div class="container-fluid h-100 px-0">
    <div
      style="position: fixed; bottom: 5px; right: 0; color: #95A5A6;z-index:20000;"
    >
      powered by VectorGPS®
    </div>
    <div
      class="row h-100 g-0"
      style="visibility: {!buscando ? 'visible' : 'hidden'};"
    >
      <div class="col-md-3 mb-3 h-100">
        <div class="card h-100 b0">
          <div class="d-flex flex-column h-100">
            <div class="card-header bg-dark text-white">
              <div class="d-flex justify-content-between">
                <div>
                  <i class="material-icons md-2">manage_accounts</i> Lista de usuarios
                  ST
                </div>
                {#if !edit_mode && !add_mode}
                  <div>
                    <button
                      type="button"
                      class="btn btn-link p-0"
                      on:click|preventDefault={() => {
                        add_mode = true;
                        empleado_e = "";
                        nombre_e = "";
                        entidad_e = "";
                        tel_e = "";
                        user_selected = -1;
                        user_name = "";
                        pass_e = "";
                      }}
                      ><i class="material-icons green md-24">add_circle</i
                      ></button
                    >
                  </div>
                {/if}
              </div>
            </div>
            <div class="card-body flex-grow-1 overflow-auto" style="padding:0;">
              <div class="list-group h-100 b0">
                {#each user_list as item, c}
                  <a
                    href="/{item}"
                    class="list-group-item list-group-item-action {user_selected ==
                    c
                      ? 'list-group-item-warning'
                      : ''}"
                    on:click|preventDefault={() => {
                      map_user(item, c);
                    }}>{item}</a
                  >
                {/each}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 mb-3 h-100">
        <div class="card h-100 b0">
          <div class="d-flex flex-column h-100">
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <div>
                  <i class="material-icons bblue md-2">info</i> Información
                </div>
                <div>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    on:click|preventDefault={() => {
                      store.removeItem("token-st-omnitracs");
                      store.removeItem("token-st-omnitracs-expire");
                      token = null;
                      app_user = "";
                      app_pass = "";
                      saved_ok = true;
                      saved_text = "Sesion cerrada";
                      saved_type = "alert-success";
                      setTimeout(function () {
                        saved_ok = false;
                      }, 3000);
                    }}>Cerrar sesion</button
                  >
                </div>
              </div>
            </div>
            <div class="card-body flex-grow-1 overflow-auto" style="padding:0;">
              {#if user_selected == -1 && !add_mode}
                <p class="m-3">Selecione un usuario</p>
              {:else}
                <div class="m-3">
                  <table class="table table-bordered">
                    <tbody>
                      {#if !edit_mode && !add_mode}
                        <tr
                          ><td><strong>Número de empleado</strong></td><td
                            >{data_user[user_name].empleado}</td
                          ></tr
                        >
                        <tr
                          ><td><strong>Nombre completo</strong></td><td
                            >{data_user[user_name].nombre}</td
                          ></tr
                        >
                        <tr
                          ><td><strong>Entidad</strong></td><td
                            >{data_user[user_name].entidad}</td
                          ></tr
                        >
                        <tr
                          ><td><strong>Teléfono</strong></td><td
                            >{data_user[user_name].tel}</td
                          ></tr
                        >
                        <tr
                          ><td><strong>Password</strong></td><td>•••••••••</td
                          ></tr
                        >
                      {:else}
                        <tr
                          ><td><strong>Número de empleado</strong></td><td
                            ><input
                              type="text"
                              class="form-control"
                              bind:value={empleado_e}
                            /></td
                          ></tr
                        >
                        <tr
                          ><td><strong>Nombre completo</strong></td><td
                            ><input
                              type="text"
                              class="form-control"
                              bind:value={nombre_e}
                            /></td
                          ></tr
                        >
                        <tr
                          ><td><strong>Entidad</strong></td><td
                            ><input
                              type="text"
                              class="form-control"
                              bind:value={entidad_e}
                            /></td
                          ></tr
                        >
                        <tr
                          ><td><strong>Teléfono</strong></td><td
                            ><input
                              type="text"
                              class="form-control"
                              bind:value={tel_e}
                            /></td
                          ></tr
                        >
                        {#if add_mode}
                          <tr
                            ><td><strong>Usuario</strong></td><td
                              ><input
                                type="text"
                                class="form-control"
                                bind:value={user_name}
                                placeholder={!add_mode ? "" : "Requerido"}
                              /></td
                            ></tr
                          >
                        {/if}
                        <tr
                          ><td
                            ><strong>Password </strong>{edit_mode &&
                            pass_e != "" &&
                            pass_e.length < 8
                              ? "(Mínimo 8 caracteres)"
                              : ""}</td
                          ><td
                            ><input
                              placeholder={!add_mode
                                ? "Dejar en blanco para conservar"
                                : "Requerido, mínimo 8 caracteres"}
                              type="password"
                              class="form-control"
                              bind:value={pass_e}
                            /></td
                          ></tr
                        >
                      {/if}
                    </tbody>
                  </table>
                </div>
                {#if !edit_mode && !add_mode}
                  <div class="d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="btn btn-primary m-2"
                      on:click|preventDefault={() => {
                        edit_mode = true;
                        empleado_e = data_user[user_name].empleado;
                        nombre_e = data_user[user_name].nombre;
                        entidad_e = data_user[user_name].entidad;
                        tel_e = data_user[user_name].tel;
                        pass_e = "";
                      }}><i class="material-icons">edit</i> Editar</button
                    >
                    <button
                      type="button"
                      class="btn btn-danger m-2 me-3"
                      on:click|preventDefault={() => {
                        confirm_delete = true;
                      }}
                      ><i class="material-icons">not_interested</i> Borrar</button
                    >
                  </div>
                {:else}
                  <div class="d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="btn btn-success m-2 me-3"
                      on:click|preventDefault={() => {
                        if (edit_mode) {
                          console.log("edit");
                          update_data();
                        } else {
                          new_user();
                          console.log("add");
                        }
                        edit_mode = false;
                        add_mode = false;
                      }}
                      disabled={(add_mode &&
                        (pass_e.length < 8 || user_name == "")) ||
                      (edit_mode && pass_e.length > 0 && pass_e.length < 8)
                        ? true
                        : false}
                      ><i class="material-icons">done</i> Guardar</button
                    >
                  </div>
                {/if}
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
    {#if buscando}
      <div
        class=""
        style="visibility: {buscando
          ? 'visible'
          : 'hidden'};position: absolute;top: 100px; left: 50%;"
      >
        <div
          class="spinner-border text-danger"
          style="width: 10rem; height: 10rem;position: absolute; left: 50%;"
          role="status"
        />
      </div>
    {/if}
  </div>
{:else}
  <div
    style="position: fixed; bottom: 5px; right: 0; color: #95A5A6;z-index:20000;"
  >
    powered by VectorGPS®
  </div>
  <form class="form-signin">
    <img
      class="mb-4"
      src="https://vector-embed.omnitracs.online/Omnitracs_Logo_RGB.png"
      alt=""
      width="250"
      style="margin-left: auto;margin-right: auto;display: block;"
    />
    <h4 style="text-align: center;color: darkgray;">ST Omnitracs Users</h4>
    <input
      type="text"
      class="form-control"
      placeholder="Usuario API"
      required
      bind:value={app_user}
    />
    <input
      type="password"
      class="form-control mt-3"
      placeholder="Password"
      required
      bind:value={app_pass}
    />
    <div class="d-grid gap-2">
      <button
        style="background-color: #07ae4c; border-color: #07ae4c;"
        class="btn btn-primary btn-block mt-4"
        type="submit"
        on:click|preventDefault={() => {
          token = app_user + ":" + app_pass;
          get_data();
        }}>Ingresar</button
      >
    </div>
    <p class="mt-5 mb-3 text-muted" style="text-align: center;">
      Omnitracs&copy; 2020-2021
    </p>
  </form>
{/if}

<style>
  .alert-top {
    position: fixed;
    top: 2rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  th {
    text-align: center;
    vertical-align: middle;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(94 233 113);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 4px;
  }
  .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #fff3cd;
  }
  .list-group.b0 {
    border-radius: 0;
  }

  :global(body) {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: #f5f5f5;
  }
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
</style>
